<template>
  <section id="prospect">
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Customer Information">
          <b-row>
            <b-col md="3" lg="2">
              <div class="detail-info">
                <span>Customer Name</span>
                <h4>{{ customerInformation.tp_client_name || '-' }}</h4>
              </div>
            </b-col>
            <b-col md="3">
              <div class="detail-info">
                <span>Contact</span>
                <h4 v-if="customerInformation.tp_client_email"><a :href="'mailto:' + customerInformation.tp_client_email" class="contact-info"><i class="ti-email"></i><span>{{ customerInformation.tp_client_email || '-'}}</span></a></h4>
                <h4 v-if="customerInformation.tp_client_phone"><a :href="'https://web.whatsapp.com/send?phone='+customerInformation.tp_client_phone.replace('08', '628')" target="child" class="contact-info"><i class="fab fa-whatsapp"></i><span>{{ customerInformation.tp_client_phone || '-'}}</span></a></h4>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <div class="detail-info">
                <span>Prospect Date</span>
                <h4>{{ customerInformation.tp_date | moment("LLLL") }}</h4>
              </div>
            </b-col>

            <b-col md="12">
              <div class="detail-info" v-if="customerInformation.tp_closing_date">
                <span>Closing Date</span>
                <h4>{{ customerInformation.tp_closing_date | moment("LL") }}</h4>
              </div>
            </b-col>
            <b-col md="6">
              <div class="detail-info">
                <span>Address</span>
                <h4>{{ customerInformation.tp_client_address || '-' }}</h4>
              </div>
            </b-col>
            <b-col md="6">
              <div class="detail-info">
                <span>General Information</span>
                <h4>{{customerInformation.tp_general_information || '-'}}</h4>
              </div>
            </b-col>
            <b-col md="6">
              <div class="detail-info">
                <span>Purpose</span>
                <h4>{{ customerInformation.tp_keperluan || '-' }}</h4>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <div class="detail-info">
                <span>Status</span>
                <div>
                  <b-badge :variant="$parent.customerBadge[customerInformation.tp_status_customer].color">
                    {{ $parent.customerBadge[customerInformation.tp_status_customer].word }}
                  </b-badge>
                </div>
              </div>
            </b-col>
            <b-col md="3">
              <div class="detail-info">
                <span>Partner</span>
                <h4>{{ customerInformation.mp_name }}</h4>
              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Follow Up History">
          <template v-if="$parent.lengthArr(followUpHistory)">
            <b-row>
              <b-col lg="8" md="10">
                <div class="timeline">
                  <div class="timeline-box" v-for="(value, key) in followUpHistory" :key="key">
                    <div class="timeline-head">
                      <h4 class="timeline__title">{{ value.tfu_date | moment('LL') }}</h4>
                      <b-badge v-if="value.tfu_status_done == 'done'" :variant="$parent.prospectBadge[value.tfu_status].color">
                        {{ $parent.prospectBadge[value.tfu_status].word }}
                      </b-badge>
                      <p v-else>Not Finished yet</p>
                      <span v-if="value.tfu_status_done == 'done'">Followed Up by: {{ value.tfu_person }}</span>
                    </div>
                    <div class="timeline-body">
                      <p>{{ value.tfu_result }}</p>
                      <p v-if="value.tfu_pending_reason">Pending Reason: {{ value.tfu_pending_reason }}</p>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <h3>Follow Up History is Empty.</h3>
          </template>
        </b-tab>
        <b-tab title="Survey History">
          <template v-if="$parent.lengthArr(surveyHistory)">
            <b-row>
              <b-col lg="6" md="8">
                <div class="detail-info">
                  <span>Survey Address</span>
                  <h4>{{ (surveyHistory[0] || {tss_address: ''}).tss_address }}</h4>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8" md="10">
                <div class="timeline">
                  <div class="timeline-box" v-for="(value, index) in surveyHistory" :key="index">
                    <div class="timeline-head">
                      <h4 class="timeline__title">{{ value.tss_date | moment('LLL') }}</h4>
                      <b-button v-if="value.tss_image" size="sm" variant="outline-info" @click="setLightBox(value.tss_image)">
                        <i class="fas fa-image"></i>
                        See Photo
                      </b-button>
                      <p>Surveyor: {{ value.ms_name }}</p>
                    </div>
                    <div class="timeline-body">
                      <p>{{value.tss_result}}</p>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <h3>Survey History is Empty.</h3>
          </template>
        </b-tab>
       <b-tab title="Payment">
          <b-row>
            <b-col md="6" lg="5" v-if="customerInformation.tp_status_customer != 'F'">
              <validation-observer mode="passive" v-slot="{ handleSubmit }" ref="VFormUpdatePayment">
                <b-form @submit.prevent="handleSubmit(updatePayment())">
                  <b-form-row>
                    <b-col md="6">
                      <div class="detail-info">
                        <span>Project Worth</span>
                        <h4>{{$parent.formatRupiah(customerInformation.tp_total_price)}}</h4>
                        <div>
                          <b-badge variant="success" v-if="customerInformation.tp_finish_payment == 'Y'">Paid</b-badge>
                          <b-badge variant="warning" v-else>Payment Ongoing</b-badge>
                        </div>
                      </div>
                    </b-col>
                    <b-col md="6">
                      <div class="detail-info">
                        <span>Remaining Payment</span>
                        <h4>{{$parent.formatRupiah(remaining)}}</h4>
                      </div>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Amount" label-for="payAmount">
                        <b-input-group prepend="Rp.">
                          <b-form-input id="payAmount" type="number" placeholder="e.g. 2.000.000" v-model="dataModal.tcp_amount" />
                        </b-input-group>
                        <VValidate mode="passive" name="Amount" v-model="dataModal.tcp_amount" rules="required" />
                      </b-form-group>
                      <div class="border p-3 mb-3">
                        <h5 class="font-weight-bold">Payment Shares</h5>
                        <b-form-row>
                          <b-col md="6">
                            <b-form-group label="Company Share" label-for="proCompShare" class="mb-0">
                              <b-input-group prepend="Rp.">
                                <b-form-input v-model="dataModal.tcp_amount_bu" id="proCompShare" type="number" placeholder="e.g. 5000000" />
                              </b-input-group>
                              <VValidate mode="passive" name="Company Share" v-model="dataModal.tcp_amount_bu" rules="required" />
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group label="Partner Share" label-for="proPartShare" class="mb-0">
                              <b-input-group prepend="Rp.">
                                <b-form-input v-model="dataModal.tcp_amount_partner" id="proPartShare" type="number" placeholder="e.g. 5000000" />
                              </b-input-group>
                              <VValidate mode="passive" name="Partner Share" v-model="dataModal.tcp_amount_partner" rules="required" />
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                      </div>
                    </b-col>
                    <b-col md="12">
                      <b-button type="submit" variant="info btn-rounded">Submit Payment</b-button>
                    </b-col>
                  </b-form-row>
                </b-form>
              </validation-observer>
            </b-col>
            <b-col md="6" lg="7">
              <b-row>
                <b-col md=12 v-if="customerInformation.tp_status_customer == 'F'">
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <div class="detail-info">
                        <span>Project Worth</span>
                        <h4>{{$parent.formatRupiah(customerInformation.tp_total_price)}}</h4>
                        <div>
                          <b-badge variant="success" v-if="customerInformation.tp_finish_payment == 'Y'">Paid</b-badge>
                          <b-badge variant="warning" v-else>Payment Ongoing</b-badge>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="detail-info">
                        <span>Remaining Payment</span>
                        <h4>{{$parent.formatRupiah(remaining)}}</h4>
                      </div>                    
                    </div>

                    <b-col md="6">
                      <div class="detail-info">
                        <span>Revenue Partner</span>
                        <h4>{{$parent.formatRupiah(revenue.partner)}}</h4>

                        <small class="d-block font-weight-bold mt-1">({{ revenuePercent.partner }}%)</small>
                      </div>
                    </b-col>

                    <b-col md="6">
                      <div class="detail-info">
                        <span>Revenue Company</span>
                        <h4>{{$parent.formatRupiah(revenue.bu)}}</h4>

                        <small class="d-block font-weight-bold mt-1">({{ revenuePercent.bu }}%)</small>
                      </div>
                    </b-col>

                    <b-col md="12">
                      <h4 class="font-weight-bold">Partner</h4>
                    </b-col>

                    <b-col md="6">
                      <div class="detail-info">
                        <span>Receive</span>
                        <h4>{{$parent.formatRupiah(received.partner)}}</h4>
                      </div>
                    </b-col>
                    <b-col md="6">
                      <div class="detail-info">
                        <span>Account Receivable</span>
                        <h4>{{$parent.formatRupiah(accountsReceivable.partner)}}</h4>
                      </div>
                    </b-col>
                    

                    <b-col md="12">
                      <h4 class="font-weight-bold">Company</h4>
                    </b-col>

                    <b-col md="6">
                      <div class="detail-info">
                        <span>Receive</span>
                        <h4>{{$parent.formatRupiah(received.bu)}}</h4>
                      </div>
                    </b-col>
                    
                    <b-col md="6">
                      <div class="detail-info">
                        <span>Account Receivable</span>
                        <h4>{{$parent.formatRupiah(accountsReceivable.bu)}}</h4>
                      </div>
                    </b-col>
                  </div>
                </b-col>
                
              </b-row>
            </b-col>
            <b-col md=12>
                  <div class="detail-info">
                    <span>Payment History</span>
                    <b-table-simple small bordered class="payment-table">
                      <b-thead>
                        <b-tr>
                          <b-th width="30%">Desc</b-th>
                          <b-th colspan="2">Amount</b-th>
                          <b-th>Paid On</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                      <template v-for="(value, index) in historyPayment">
                          <b-tr :key="'A' + index">
                            <b-td rowspan="2">{{ value.tcp_description }}</b-td>
                            <b-td colspan="2">{{ $parent.formatRupiah(value.tcp_amount) }}</b-td>
                            <b-td rowspan="2">{{ value.tcp_date | moment('MMM D') }}, {{ value.tcp_date | moment('YYYY') }}</b-td>
                          </b-tr>
                          <b-tr :key="'B' + index">
                            <b-td>
                              <b-form-group label="Partner Share" label-for="comPartner" label-class="mb-1" class="mb-0">
                                <span>{{ $parent.formatRupiah(value.tcp_amount_partner) }}</span>
                              </b-form-group>
                            </b-td>
                            <b-td>
                              <b-form-group label="Company Share" label-for="comShare" label-class="mb-1" class="mb-0">
                                <span>{{ $parent.formatRupiah(value.tcp_amount_bu) }}</span>
                              </b-form-group>
                            </b-td>
                          </b-tr>
                        </template>
                        <b-tr class="bg-info text-white">
                          <b-th colspan="2" class="text-right">Remaining Payment</b-th>
                          <b-td colspan="2">{{$parent.formatRupiah(remaining)}}</b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                </b-col>

                <b-col md=12 v-if="historyPayment && $parent.lengthArr(historyPayment.filter(x => x.tcp_loss_cost && x.tcp_loss_cost > 0))">
                  <div class="detail-info">
                    <span>Loss Cost History</span>
                    <b-table-simple small bordered class="payment-table">
                      <b-thead>
                        <b-tr>
                          <b-th>Desc</b-th>
                          <b-th>Amount</b-th>
                          <b-th>Reason</b-th>
                          <b-th>Paid On</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                      <template v-for="(value, index) in historyPayment.filter(x => x.tcp_loss_cost && x.tcp_loss_cost > 0)">
                          <b-tr :key="'LCA' + index">
                            <b-td width="30%">{{ value.tcp_description }}</b-td>
                            <b-td>{{ $parent.formatRupiah(value.tcp_loss_cost) }}</b-td>
                            <b-td>{{ value.tcp_reason_loss_cost || '-' }}</b-td>
                            <b-td>{{ value.tcp_date | moment('MMM D') }}, {{ value.tcp_date | moment('YYYY') }}</b-td>
                          </b-tr>
                        </template>
                      </b-tbody>
                    </b-table-simple>
                  </div>
                </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Monitoring History">
          <template v-if="$parent.lengthArr(monitorHistory)">
            <b-row>
              <b-col lg="8" md="10">
                <div class="timeline">
                  <div class="timeline-box" v-for="(value, index) in monitorHistory" :key="index">
                    <div class="timeline-head">
                      <h4 class="timeline__title">{{ value.tss_date | moment('LLL') }}</h4>
                      <b-button v-if="value.tss_image" size="sm" variant="outline-info" @click="setLightBox(value.tss_image)">
                        <i class="fas fa-image"></i>
                        See Photo
                      </b-button>
                      <b-button
                        class="ml-2 btn-icon"
                        variant="outline-warning"
                        v-b-tooltip.hover="'Edit Photo'"
                        v-if="value.tss_image"
                        @click="showUpdateImage(value.tss_image, value.tss_id)"
                        size="sm"
                      ><i class="fas fa-pencil-alt"></i>
                      </b-button>
                      <p>Surveyor: {{ value.ms_name }}</p>
                    </div>
                    <div class="timeline-body">
                      <p>{{ value.tss_result }}</p>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <h3>Monitor History is Empty.</h3>
          </template>
        </b-tab>
        <b-tab title="Guarantee, Complaint and Testimonial" active no-body>
          <b-card-body>
            <b-alert variant="danger" show v-if="customerInformation.count_complaint">
              <i class="fas fa-exclamation-circle mr-2"></i>
              <span>{{customerInformation.count_complaint}} complaints need to be resolved.</span>
            </b-alert>
            <b-alert variant="danger" show v-if="!customerInformation.tp_guarantee">
              <i class="fas fa-exclamation-circle mr-2"></i>
              <span>Guarantee hasn't been added for this project.</span>
            </b-alert>
            <b-alert variant="info" show v-if="!customerInformation.tp_testimoni">
              <i class="fas fa-info-circle mr-2"></i>
              <span>Add Testimonial for this project.</span>
            </b-alert>
            <b-row>
              <b-col md=5>
                <validation-observer v-slot="{ handleSubmit }" ref="VFormAfterSales">
                  <b-form @submit.prevent="handleSubmit(addAfterSales())">
                    <b-form-row>
                      <b-col md="12">
                        <b-row>
                          <b-col md="6">
                            <b-form-group v-if="$parent.moduleRole('add_guarantee')" label-for="guarantee" label="Service Guaranteed until">
                              <div class="d-flex align-items-center">
                                <date-picker type="date" value-type="format" format="YYYY-MM-DD" id="guaranteedate" prefix-class="crm" v-model="customerInformation.tp_guarantee" :disabled-date="notBeforeToday" />
                              </div>
                              <VValidate name="Guarantee Until" v-model="customerInformation.tp_guarantee" rules="required" />
                            </b-form-group>
                            <span v-else>Guarantee Untill : {{customerInformation.tp_guarantee | moment('LL') }}</span>
                          </b-col>
                          <b-col md="12">
                            <b-form-group label-for="testimonial" v-if="$parent.moduleRole('add_testimonial')">
                              <template #label>Project Testimonial <small>(Optional)</small></template>
                              <b-form-textarea v-model="customerInformation.tp_testimoni" id="testimonial" placeholder="e.g. Servis yang diberikan sangat memuaskan" rows="4" />
                            </b-form-group>
                            <div v-else>
                              <span>Testimonial</span>
                              <br />
                              <span>{{ customerInformation.tp_testimoni }}</span>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row class="justify-content-end">
                          <b-col md=3>
                            <b-button v-if="$parent.moduleRole('add_guarantee') || $parent.moduleRole('add_testimonial')" size="sm" variant="info" type="submit">Change Submit</b-button>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-form-row>
                  </b-form>
                </validation-observer>
              </b-col>
              <b-col md="7">
                <b-form-group label-class="mb-1" label-for="complaints">
                  <template #label>
                    Complaints
                    <b-button v-if="$parent.moduleRole('add_complaint')" class="ml-1" variant="outline-info" size="sm" v-b-modal.complaintForm>
                      <i class="fas fa-plus mr-2"></i>
                      Add Complaint
                    </b-button>
                  </template>
                  <b-table
                    id="complaints"
                    class="mt-2"
                    :fields="complaintFields"
                    :items="complaints"
                    striped
                    hover
                    show-empty
                  >
                  <template #empty>
                    <div class="text-center">
                      <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
                      <h4 align="center">No Complaints found.</h4>
                    </div>
                  </template>
                    <template #cell(num)="data">{{ data.index += 1 }}</template>
                    <template #cell(complaint)="data">
                      {{ data.item.tc_description.length > 95 ? data.item.tc_description.substr(0, 95) + '...' : data.item.tc_description }}
                    </template>
                    <template #cell(status)="data">
                      <b-badge :variant="data.item.tc_is_resolve == 'Y' ? 'success' : 'danger'">{{ data.item.tc_is_resolve == 'Y' ? 'Resolved' : 'Not Yet Resolved' }}</b-badge>
                    </template>
                    <template #cell(action)="data">
                      <template v-if="$parent.moduleRole('add_complaint')">
                        <b-button
                          variant="success"
                          class="btn-icon"
                          v-b-tooltip.hover="'Resolve Complaint'"
                          v-if="data.item.tc_is_resolve !== 'Y'"
                          @click="doResolveComplaint(data.item)"
                        >
                          <i class="fas fa-check"></i>
                        </b-button>
                        <b-button
                          variant="outline-info"
                          class="btn-icon"
                          v-b-tooltip.hover="'View Details'"
                          @click="viewComplaintDetails(data.item)"
                        >
                          <i class="fas fa-eye"></i>
                        </b-button>
                        <b-button
                          variant="outline-danger"
                          class="btn-icon"
                          v-b-tooltip.hover="'Delete Complaint'"
                          @click="deleteComplaint(data.item.tc_id)"
                        >
                          <i class="fas fa-trash-alt"></i>
                        </b-button>
                      </template>
                      <template v-else>-</template>
                    </template>
                  </b-table>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-tab>
      </b-tabs>
    </b-card>
    <div class="media-overlay" :class="showSurveyImg ? 'show' : ''" @click.stop="showSurveyImg = false">
      <div class="media-content" @click.stop>
        <div class="media-dialog">
          <b-img :src="$parent.uploader(detailImg)" />
          <b-button size="sm" @click.stop="showSurveyImg = false" class="close-button btn" variant="danger"><i class="fas fa-times"></i></b-button>
        </div>
      </div>
    </div>
    <b-modal
      id="complaintForm"
      title="Complaint Form"
      centered
      no-close-on-backdrop
    >
      <validation-observer v-slot="{ handleSubmit }" ref="VFormComplaint">
        <b-form @submit.prevent="handleSubmit(addComplaint())">
          <b-form-group label="Complained On" label-for="complaintDate">
            <date-picker type="date" id="complaintDate" value-type="format" format="YYYY-MM-DD" prefix-class="crm" v-model="dataModal.tc_date" :disabled-date="notBeforeToday"/>
            <VValidate name="Complained On" v-model="dataModal.tc_date" rules="required" />
          </b-form-group>
          <b-form-group label="Complaint Description" label-for="complaintDescription">
            <b-form-textarea
              id="complaintDescription"
              v-model="dataModal.tc_description"
              placeholder="e.g. Masih ada bagian lantai marmer yang kurang bersih"
            />
            <VValidate name="Complaint Description" v-model="dataModal.tc_description" rules="required|min:5" />
          </b-form-group>
          <b-form-group label-for="monPhoto">
            <template #label>Complaint Photo</template>
            <Uploader v-model="dataModal.tc_image" :usePreview="true" type="survey_result"/>
            <VValidate name="Complaint Photo" v-model="dataModal.tc_image" rules="required" />
          </b-form-group>
          <b-form-group>
            <b-form-checkbox v-model="dataModal.tc_is_resolve">Has Complaint Resolved?</b-form-checkbox>
          </b-form-group>
          <b-form-group v-if="dataModal.tc_is_resolve" label="Action Taken" label-for="compResolve">
            <b-form-textarea v-model="dataModal.tc_resolve" rows="6" id="compResolve" placeholder="e.g. Partner mendatangi kembali tempat customer untuk menyelesaikan masalah pada furnitur" />
            <VValidate name="Action Taken" v-model="dataModal.tc_resolve" :rules="dataModal.tc_is_resolve ? 'required|min:5' : ''" />
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button size="md" variant="transparent" @click="$bvModal.hide('complaintForm')">Cancel</b-button>
        <b-button size="md" variant="info" @click="addComplaint()">Add Complaint</b-button>
      </template>
    </b-modal>

    <b-modal
      id="complaintDetails"
      title="Complaint Details"
      centered
      no-close-on-backdrop
    >
      <validation-observer v-slot="{ handleSubmit }" ref="VFormResolve">
        <b-form @submit.prevent="handleSubmit(submitResolve())">
          <b-form-row>
            <b-col md="6">
              <b-form-group label="Has Resolved?" label-for="cmpStatus" label-class="mb-1">
                <b-badge :variant="complaintDetail.tc_is_resolve == 'Y' ? 'success' : 'danger'">{{ complaintDetail.tc_is_resolve == 'Y' ? 'Resolved' : 'Not Yet Resolved' }}</b-badge>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Complained On" label-for="cmpDate" label-class="mb-1">
                <span>{{complaintDetail.tc_date | moment('MMM D')}}, {{complaintDetail.tc_date | moment('YYYY')}}</span>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Description" label-for="complaint" label-class="mb-1">
                <span>{{complaintDetail.tc_description}}</span>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Image" label-for="complaintPhoto" label-class="mb-1">
                <img class="mb-2" :src="$parent.uploader(complaintDetail.tc_image)" alt="Complaint Media">
              </b-form-group>
            </b-col>
            <b-col md=12>
              <template v-if="complaintDetail.tc_is_resolve == 'Y'">
                <b-form-group label="Action Taken" label-for="compAction" label-class="mb-1">
                  <span>{{ complaintDetail.tc_resolve }}</span>
                </b-form-group>
              </template>
              <template v-else>
                <b-form-group label="Action Taken" label-for="compResolve" v-if="resolveComplaint" class="mt-3">
                  <b-form-textarea rows="6" v-model="dataModal.tc_resolve" id="compResolve" placeholder="e.g. Partner mendatangi kembali tempat customer untuk menyelesaikan masalah pada furnitur" required />
                  <VValidate name="Action Taken" v-model="dataModal.tc_resolve" rules="required|min:5" />
                </b-form-group>
              </template>
            </b-col>
          </b-form-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button size="md" v-if="resolveComplaint" variant="transparent" @click="$bvModal.hide('complaintDetails')">Cancel</b-button>
        <b-button size="md" v-if="resolveComplaint" variant="info" @click="submitResolve()">Resolve Complaint</b-button>
        <b-button size="md" v-else variant="info" @click="$bvModal.hide('complaintDetails')">Close</b-button>
      </template>
    </b-modal>
  </section>
</template>
<script>
  import Gen from '@/libs/Gen'

  export default{
    props:{
      customerInformation: Object,
      followUpHistory: Array,
      surveyHistory: Array,
      remaining: Number,
      historyPayment: Array,
      monitorHistory: Array,
      cost_customer: [String, Number],
      detailImg: String,
      complaints: Array,
      accountsReceivable: [Array, Object],
      received: [Array, Object],
      revenue: [Array, Object],    
      revenuePercent: [Array, Object],
    },
    data() {
      return {
        showSurveyImg: false,
        dataModal: {},
        complaintFields: [
          { key: 'num', label: '#' },
          { key: 'complaint', label: 'Complaint' },
          { key: 'status', label: 'Has Resolved?' },
          { key: 'action', label: 'Action' },
        ],
        complaintDetail: {},
        resolveComplaint: false,
      }
    },
    methods: {
      doResolveComplaint(data){
        this.resolveComplaint = true
        this.$set(this, 'complaintDetail', data)

        this.$bvModal.show('complaintDetails')
      },
      viewComplaintDetails(data){
        this.resolveComplaint = false
        this.$set(this, 'complaintDetail', data)

        this.$bvModal.show('complaintDetails')
      },
      notBeforeToday(date) {
        return date ?  false : false
        // return date < new Date(new Date().setHours(0, 0, 0, 0))
      },
      setLightBox(src){
        this.detailImg = src
        this.showSurveyImg = true
      },
      addComplaint(){
        this.$refs.VFormComplaint.validate().then(success => {
          if(!success) return

          this.dataModal.tp_code = this.customerInformation.tp_code

          this.$swal({
          title: 'Add Complaint...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          '/do/' + this.$parent.modulePage,
          { data: {type: 'add-complaint', ...this.dataModal} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.$set(this, 'dataModal', {})
                this.$bvModal.hide('complaintForm')
                this.$parent.apiGet()
              })
          }
        }).catch(() => {
          this.$bvModal.hide('complaintForm')
          this.$set(this, 'dataModal', {})
          this.$parent.apiGet()
        })

        })
      },
      submitResolve(){
        this.$refs.VFormResolve.validate().then(success => {
          if(!success) return

          this.dataModal.tp_code = this.customerInformation.tp_code
          this.dataModal.tc_id = this.complaintDetail.tc_id

          this.$swal({
          title: 'Resolve Complaint...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          '/do/' + this.$parent.modulePage,
          { data: {type: 'resolve-complaint', ...this.dataModal} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.$set(this, 'dataModal', {})
                this.$bvModal.hide('complaintDetails')
                this.$parent.apiGet()
              })
          }
        }).catch(() => {
          this.$bvModal.hide('complaintDetails')
          this.$set(this, 'dataModal', {})
          this.$parent.apiGet()
        })

        })
      },
      addAfterSales(){
        this.$refs.VFormAfterSales.validate().then(success => {
          if(!success) return

          this.dataModal.tp_code = this.customerInformation.tp_code
          this.dataModal.tp_guarantee = this.customerInformation.tp_guarantee
          this.dataModal.tp_testimoni = this.customerInformation.tp_testimoni

          this.$swal({
          title: 'Update...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          '/do/' + this.$parent.modulePage,
          { data: {type: 'update-aftersales', ...this.dataModal} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.$set(this, 'dataModal', {})
                this.$parent.apiGet()
              })
          }
        }).catch(() => {
            this.$set(this, 'dataModal', {})
            this.$parent.apiGet()
        })

        })
      },
      deleteComplaint(id){
        this.$swal({
            title: 'Are you sure to delete this complaint?',
            showConfirmButton: true,
            showCancelButton: true,
            confirmCancelText: 'Cancel',
            confirmButtonText: 'Yes, Sure',
            icon: 'warning',
          }).then(result => {
            if(result.value){
              Gen.apiRest(
                '/do/' + this.$parent.modulePage,
                { data: {type: 'delete-complaint', id} },
                'POST'
              ).then(res => {
                if(res){
                    this.$swal({
                      title: res.data.message,
                      text: '',
                      timer: 1500,
                      icon: 'success',
                    }).then(() => {
                      this.$parent.apiGet()
                    })
                }
              }).catch(() => {
                this.$parent.apiGet()
              })
            }
        })
      },
    },
    mounted(){
      setTimeout(() => {       
        if(!this.customerInformation) this.$parent.$router.push({ name: this.$parent.modulePage })      
       }, 800)
    }
  }
</script>
